import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Box, Badge } from "../components/Core";
import CaseLeft from "../components/CaseLeft";

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  features,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet}
      <Section className="pb-5">
        <div className="pt-3"></div>
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="12">
              <Title variant="hero">{title}</Title>
              <Box className="d-flex justify-content-center">
                <Text mr={3}>{description}</Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="container content">
        {features &&
          features.map((feature, i) => (
            <CaseLeft
              title={feature.title}
              description={feature.text}
              featuredimage={feature.image}
              imgSize={feature.image_size}
              key={i}
            />
          ))}
        <Container>
          <PostContent content={content} />
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <h4>Tags</h4>
              <ul className="taglist" style={{ paddingInlineStart: 0 }}>
                {tags.map((tag, i) => (
                  // <Link to={`/tags/${kebabCase(tag)}/`} key={i}>
                  <BadgePost>{tag}</BadgePost>
                  // </Link>
                ))}
              </ul>
            </div>
          ) : null}
        </Container>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        features={post.frontmatter.features}
      />
    </PageWrapper>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        features {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_size
        }
      }
    }
  }
`;
