import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "./Core";
import { device } from "../utils";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const ShapeCard = styled(Box)`
  width: 175px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  top: 64%;
  left: 6%;
  transform: scale(0.85);
  @media ${device.sm} {
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
    top: 5%;
  }
`;

const CaseLeft = ({
  props,
  title,
  description,
  clientlogo,
  featuredimage,
  url,
  imgSize = "big",
}) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section py={5}>
      <Container>
        <Row className="align-items-top">
          <Col
            md="12"
            lg={imgSize === "small" ? "4" : "6"}
            className="order-lg-2"
          >
            <div className="position-relative pl-lg-5">
              <div
                className="pl-lg-4 text-center text-lg-right"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                  }}
                  style={{ borderRadius: 8 }}
                />
              </div>

              {clientlogo && (
                <ShapeCard
                  bg="white"
                  p="18px"
                  borderRadius={8}
                  data-aos="fade-right"
                  data-aos-duration="500"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: clientlogo,
                    }}
                  />
                </ShapeCard>
              )}
            </div>
          </Col>
          <Col
            lg={imgSize === "small" ? "8" : "6"}
            className="order-lg-1 mt-5 mt-lg-0"
          >
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <Box>
                {title && <Title>{title}</Title>}
                <Text mb={4}>{description}</Text>

                {url && (
                  <Link to={url}>
                    <Button mt={3}>{props.cta}</Button>
                  </Link>
                )}
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseLeft;
